@import "~bootstrap/scss/bootstrap";

/* color scheme */
$dark: rgba(73, 73, 73, 1);
$red: rgba(237, 106, 90, 1);
$light: rgba(252, 222, 190, 1);
$blue: rgba(125, 128, 218, 1);
$white: rgba(250, 250, 255, 1);
$green: rgba(155, 193, 188, 1);

/* font families */
$sansSerif: 'Raleway', sans-serif;
$serif: 'Lora', serif;

html, body, #root {
    height: 100%;
    margin: 0; }

.home-hero {
    background-image: url('./img/DSCF5491.jpg');
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $dark;
    color: white;
    text-shadow: -1px -1px 5px rgba(255, 255, 255, 0.1), 1px 1px 5px rgba(0, 0, 0, 0.5);
    @supports (-webkit-overflow-scrolling: touch) {
        background-attachment: scroll; }
    .row {
        background-color: rgba(0, 0, 0, 0.4); }
    &-title {
        font-size: 4.5rem;
        font-family: $sansSerif;
        font-weight: bold; }
    &-desc {
        font-size: 2.5rem;
        font-family: $serif; }
    &--links {
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.1);
        border-top-right-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        border-top-left-radius: 0rem;
        -webkit-box-shadow: 5px 5px 5px -4px rgba(102,102,102,0.2);
        -moz-box-shadow: 5px 5px 5px -4px rgba(102,102,102,0.2);
        box-shadow: 5px 5px 5px -4px rgba(102,102,102,0.2);
        @include media-breakpoint-down(sm) {
            bottom: 0;
            right: 0;
            top: auto;
            margin: auto;
            border-top-right-radius: 0.75rem;
            border-top-left-radius: 0.75rem;
            border-bottom-right-radius: 0; }
        a {
            color: #FFFFFF;
            transition: color 0.3s, font-size 0.3s, opacity 0.3s;
            font-size: 1.6rem;
            opacity: 0.6;
            &:hover {
                color: $red;
                opacity: 1;
                font-size: 1.9rem;
                cursor: pointer;
                text-decoration: none; } } }
    a {
        color: #FFFFFF; } }

@include media-breakpoint-only(xs) {
    .home-hero {
        &-title {
            font-size: 3.5rem; }
        &-desc {
            font-size: 1.5rem; } } }

.projects {
    &--container {
        background-color: $green;
        background-image: url('./img/subtle_white_feathers_@2X.png');
        background-size: 500px 333px; }
    &--card {
        background-color: $white;
        border-radius: 0.75rem;
        overflow: hidden;
        position: relative;
        color: $dark;
        padding-bottom: 56px;
        -webkit-box-shadow: 5px 5px 5px -4px rgba(102,102,102,0.5);
        -moz-box-shadow: 5px 5px 5px -4px rgba(102,102,102,0.5);
        box-shadow: 5px 5px 5px -4px rgba(102,102,102,0.5); }
    &--img-container {
        overflow: hidden;
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem; }
    &--links-container {
        background-color: $light;
        position: absolute;
        bottom: 0; }
    &--link {
        background-color: $red;
        color: $white;
        border-radius: 1.5rem;
        font-weight: bold;
        transition: background-color 0.3s;
        &:hover {
            background-color: $blue;
            color: $white;
            text-decoration: none; } }
    &--title {
        font-size: 21px;
        font-family: $serif;
        font-weight: bold;
        background-color: $white;
        color: $blue;
        transition: color 0.3s;
        &:hover {
            color: $red;
            text-decoration: none; } }
    &--deets {
        background-color: $white;
        span {
            font-size: 14px;
            font-style: italic; }
        small {
            font-style: italic; } } }

.bio {
    &--container {
        background-color: $dark;
        color: $white;
        p {
            font-family: $serif; }
        #animation_container, #dom_overlay_container {
            width: 75%!important;
            height: auto!important; }
        @include media-breakpoint-only(xl) {
            #animation_container, #dom_overlay_container {
                width: 75%!important;
                height: auto!important; } } }
    &--text {
        background-color: $dark;
        p {
            font-size: 18px; }
        img {
            -webkit-box-shadow: 5px 5px 5px -4px rgba(0,0,0,0.5);
            -moz-box-shadow: 5px 5px 5px -4px rgba(0,0,0,0.5);
            box-shadow: 5px 5px 5px -4px rgba(0,0,0,0.5); } }
    &--title {
        font-family: $sansSerif!important;
        font-weight: bold;
        font-size: 32px!important; }
    &--animation {
        background-color: $blue;
        background-image: url('./img/animation-background.jpg');
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        @include media-breakpoint-down(sm) {
            height: 600px; } }
    &--links {
        border-top: 1px solid rgba(250, 250, 255, 0.2);
        width: auto;
        a {
            color: #FFFFFF;
            font-family: $sansSerif;
            font-weight: bold;
            transition: color 0.3s, font-size 0.3s;
            font-size: 1.2rem;
            &:hover {
                color: $red;
                cursor: pointer;
                text-decoration: none;
                font-size: 1.3rem;
                @include media-breakpoint-down(sm) {
                    font-size: 1.2rem; } } } } }


[data-scroll] {
    transition: opacity 1s, margin-right 1s; }


[data-scroll="in"] {
    opacity: 1;
    left: 0;
    margin-right: 0px!important; }


[data-scroll="out"] {
    opacity: 0;
    left: 25px;
    margin-right: 75px!important; }


#canvas[data-scroll] {
    transition: top 5s, width 5s, left 5s, height 5s, opacity .5s; }

#canvas[data-scroll="in"] {
    top: 200px;
    width: 75%!important;
    height: auto!important;
    left: 120px;
    opacity: 1; }

#canvas[data-scroll="out"] {
    top: 150px;
    width: 10px!important;
    height: 10px!important;
    left: 120px;
    opacity: 0; }

